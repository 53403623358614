@import url('https://fonts.googleapis.com/css?family=Roboto');
/* Global Styles */

:root {
  --typical-color: #000000;
  --primary-color: #003699;
  --secondary-color: #c0c0c0;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --bright-color: #ffffff;
  --golden-color: #daa520;
}

* {
  box-sizing: border-box;
  margin: 1.0px;
  padding: 2.5;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #241414;
  margin: 1.0px .5px 1.0px 0.5px;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */

.container {
  max-width: 100vw;
  margin: 0.1 0 0 0px;
  overflow: hidden;
  padding: 0 0px;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding: 1px;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Icons */

.syringe {
  font-size: 1.5rem;
  color: var(--success-color);
}

/* Text Styles*/

.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* Shadow Enhancements */

.greeting {
  text-align: left;
  color: #32cd32;
  text-shadow: 1px 1px 2px #000000;
}

.logo {
  color: (var(--bright-color));
  text-shadow: 1px 1px 2px #32cd32;
}

.logo2 {
  color: (var(--danger-color));
  text-shadow: 1px 1px 2px #32cd32;
}

/* Carousel */

.carousel-item>img {
  width: 100%;
}

.carousel-inner>.item>img, .carousel-inner>.item>a>img {
  width: 100%;
}

/* Cards */

.card {
  padding: 0rem;
  border: #8d8fff 1rem 1.5rem 2.5rem 1px;
  margin: 0.0rem 0rem 0rem 0rem;
  width: 100vw;
}

/* Early Alerts */

.card>.Button>.a {
  text-decoration: none;
  color: #241414;
}

/* Liblt */

.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */

.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.p-c {
  text-align: center;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */

.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

/* Grid */

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.grid-2-3 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 2rem;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: .5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.badge {
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary, .bg-primary, .badge-primary, .alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light, .bg-light, .badge-light, .alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark, .bg-dark, .badge-dark, .alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger, .bg-danger, .badge-danger, .alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success, .bg-success, .badge-success, .alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white, .bg-white, .badge-white, .alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light, .badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */

input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type='text'], input[type='email'], input[type='password'], input[type='radio'], input[type='date'], select, textarea {
  display: block;
  width: 100%;
  margin: 3px 0 3px 3px;
  padding: .5rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

input[type='submit'], button {
  font: inherit;
}

.form-group {
  margin: 1.2rem 1.2rem;
}

.form-group input {
  margin: 0.2rem 0;
}

.form-container {
  max-width: 500px;
  margin: 2rem auto;
  overflow: hidden;
  padding: 0 2rem;
}

.form-container h1, h2, h3 {
  text-align: center;
}

table th, table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

/* Radio Buttons */

.radio-container {
  box-sizing: border-box;
  font-family: ‘Open Sans’, sans-serif;
  font-size: 13px;
  line-height: 30px;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
}

.radio-container input {
  box-sizing: border-box;
  margin: 5px;
  outline: 0;
  padding: 3rem;
  position: relative;
  top: 9px;
  vertical-align: top;
}

/* Navbar */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1.0rem;
  z-index: 1;
  width: 100%;
  /* border-bottom: solid 1px var(--primary-color); */
  opacity: 0.9;
  text-decoration: none;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: var(--light-color);
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  text-decoration: none;
  color: var(--bright-color);
}

.navbar .welcome span {
  margin-right: 1.5rem;
}

/* Footer */

.footer {
  height: -90;
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* Animation (Add After) */

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

/* Mobile Styles */

@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }
  .grid-2, .grid-3, .grid-4 {
    grid-template-columns: 1fr;
  }
  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }
  .large {
    font-size: 2rem;
  }
  .lead {
    font-size: 1rem;
  }
  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }
  .navbar h1 {
    margin-bottom: 0.6rem;
  }
  .navbar ul {
    text-align: center;
    justify-content: center;
  }
  .carousel-item>img {
    width: 100%;
    height: 30vh;
    padding: 1rem;
  }
}